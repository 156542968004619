@import 'styles/spacing.scss';

.SupportTicketsListDownloadModal {
  &__form-wrapper-row {
    display: flex;
    flex-direction: row;
    gap: $spacing-l;
    width: 100%;
  }
  &__form-wrapper-column {
    width: 50%;
    .ant-form-item {
      margin-block: $spacing-l;
    }
    .ant-picker {
      width: 100%;
    }
  }
}