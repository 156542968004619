@import 'styles/spacing.scss';
@import 'styles/color.scss';

$height: 248px;

.AssetSupportTicketsStatisticsLoading {
  &__container {
    background-color: $color-background;
    min-height: $height;
    border-radius: 8px;
    padding: $spacing-m;
    margin-bottom: $spacing-m;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;
  }

  &__header {
    width: 117px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__header-skeleton {
    height: 50px !important;
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    gap: $spacing-m;
    flex-wrap: wrap;
  }

  &__skeleton-wrapper {
    flex: 1;
    min-width: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__skeleton {
    width: 100% !important;
    height: calc($height - 50px - ($spacing-m * 3)) !important;
  }
}
