@import 'styles/spacing.scss';

.LegalDocumentationForm {
  &__wrapper {
    width: 40%;
    min-width: 300px;
  }

  &__extra-info {
    margin-right: $spacing-xs;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  &__footer-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  &__right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $spacing-xs;
    width: 100%;
  }

  &__uploaders {
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    &--disabled {
      opacity: 0.6;
    }
  }
}
