@import 'styles/spacing.scss';

.LoginPage {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    width: 300px;
    margin-bottom: $spacing-xl;
  }

  &__form {
    width: 300px;
  }

  &__submit {
    width: 100%;
  }
}
