@import 'styles/color.scss';

.HealthProgress {
  &__health {
    width: 100px;
  }

  &__info {
    font-weight: 400;
    color: $color-alert;
  }
}
