.ImagePickerDisplay {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;
    span {
      width: auto;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0 !important;
}
