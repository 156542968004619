@import 'styles/global.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.SideEvents {
  display: flex;
  flex-direction: row;
  width: 30%;

  @include medium-devices {
    width: 100%;
  }

  @include medium-devices {
    flex-direction: column;
    align-items: center;
  }

  &__divider {
    &-horizontal {
      display: none !important;
      @include medium-devices {
        display: inline !important;
      }
    }
    &-vertical {
      height: auto !important;
      display: inline !important;
      @include medium-devices {
        display: none !important;
      }
    }
  }

  &__content {
    padding: $spacing-s;
  }
}
