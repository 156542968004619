@import 'styles/color.scss';
@import 'styles/spacing.scss';

.AppPreview {
  &__container {
    width: 375px;
    height: 470px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $color-gray-extra-extra-light;
    overflow: hidden;

    -webkit-box-shadow: 0px 8px 28px -6px $color-black-25;
    -moz-box-shadow: 0px 8px 28px -6px $color-black-25;
    box-shadow: 0px 8px 28px -6px $color-black-25;
  }

  &__header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-m;
    border-bottom: solid 1px $color-gray-extra-extra-light;
    background-color: $color-background;

    &-right {
      display: flex;
      flex-direction: row;
    }
  }

  &__icon {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    overflow: hidden;
    background-color: $color-gray-extra-light;
    margin: $spacing-xs;
  }

  &__body {
    &-image {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      & img {
        min-width: 100%;
        min-height: 100%;
        background-color: $color-gray-extra-light;
      }
    }

    &-info {
      margin: $spacing-m;
      background-color: $color-gray-extra-light;
      border-radius: 8px;
      width: auto;
      height: 180px;
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      & div {
        width: 100%;
        height: 50px;
        background-color: $color-white;
      }
    }
  }
}
