@import 'styles/color.scss';
@import 'styles/media-queries.scss';

.RegisterExpertForm {
  width: 400px;

  @include small-devices {
    width: 80%;
  }

  &__submit {
    width: 100%;
  }
}
