
@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/spacing.scss';

.NoGatewayAssigned {
  &__contextualMenu {
    width: $spacing-xl;
    margin-left: auto;
  }
  &__textContainer {
    display: flex;
    height: 100vh;
    justify-content: center;
    margin-top: 128px;
    width: 100%;
  }
  &__textContent {
    display: flex;
    flex-direction: column;
    height: 216px;
    padding: $spacing-xl;
    width: 366px;
  }
  &__title {
    color: $color-text-title;
    font-size: $font-size-xl;
    font-weight: 500;
    margin-bottom: $spacing-m;
  }
  &__subtitle {
    color: $color-text-subtitle;
    font-size: $font-size-l;
    font-weight: 400;
    margin-bottom: $spacing-m;
  }
  &__button {
    align-self: flex-end;
    margin-top: auto;
  }
}
