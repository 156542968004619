@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.CommonAreaDetailTemplate {
  &__body {
    display: flex;
    padding: $spacing-xl;
    flex-direction: column;
    width: 100%;

    &-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}
