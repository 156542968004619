@import 'styles/global.scss';
@import 'styles/spacing.scss';

.ProjectsPage {
  &__header {
    @extend %header;
  }

  &__columns {
    height: auto;
    width: 40px;
    margin-right: $spacing-xs;
  }

  &__wrapper {
    @extend %pageWrapper;
  }
}
