@import 'styles/spacing.scss';
@import 'styles/font.scss';

.ContextualMenu {
  &__icon {
    cursor: pointer;
    margin-right: $spacing-l;
    font-size: $font-size-xl;
  }
}
