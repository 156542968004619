@import 'styles/spacing.scss';

.AssetConsumptionForm {
  &__element {
    margin-bottom: $spacing-s;
  }

  &__button {
    margin-bottom: $spacing-s;
    margin-right: $spacing-m;
  }
}
