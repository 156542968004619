@import 'styles/spacing.scss';

.HeaderDescription {
  &__link {
    margin-top: -$spacing-xxs;
    margin-left: -$spacing-s;
  }

  &__requireInfo {
    margin-left: $spacing-xs;
  }
}
