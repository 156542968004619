@import 'styles/color.scss';

.SegmentedControl {
  &__container {
    background-color: $color-gray-extra-extra-light;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__fillContainer {
    display: flex;
    align-items: center;
    width: 96%;
    height: 92%;
    background-color: $color-background;
    border-radius: 6px;
  }

  &__trackContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30px;
  }

  &__tabText {
    color: $color-text;
    font-size: 18px;
    margin: 0;
    padding: 1px;
    cursor: pointer;
  }

  &__tabContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    background-color: transparent;
    border-right: 1px solid $color-corporate-light;

    &:last-child {
      background-color: transparent;
      border: none;
    }

    &--selected {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $color-corporate-light;
      border: none;
      border: 3px solid $color-corporate-light;
      border-radius: 7px 0px 0px 7px;

      &-last {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: $color-corporate-light;
        border: none;
        border: 3px solid $color-corporate-light;
        border-radius: 0px 7px 7px 0px;
      }
    }
  }
}
