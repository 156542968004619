@import 'styles/color.scss';
@import 'styles/spacing.scss';

.AvatarMenu {
  position: relative;
  cursor: pointer;
}
.logoContainer {
  display: flex;
}

.beamerItem {
  &__avatar {
    span + .beamer_icon {
      position: absolute;
      left: 28px;
      top: 14px;
      color: transparent;
      width: 8px;
      height: 8px;
      background-color: $color-corporate-sunset-red;
    }
  }

  &__newsUpdates {
    .beamer_icon.active {
      position: absolute;
      right: 26px;
      top: 5px;
      color: transparent;
      width: 8px;
      height: 8px;
      background-color: $color-corporate-sunset-red;
    }
  }
}

.divider__wrapper {
  margin: 0 $spacing-xs;
}

.divider {
  margin: $spacing-xs 0 $spacing-m 0 !important;
}

.waterMark__wrapper {
  margin: 0 $spacing-xxl $spacing-xl;
}
