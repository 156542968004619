@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AssignGatewayToAssetModal {
  &__wrapper {
    min-height: 300px;
  }

  &__buttons-gap {
    margin-bottom: $spacing-l;
  }

  &__search-button {
    margin-top: $spacing-s;
  }

  &__error-message-wrapper {
    height: 32px;
  }

  &__error-message {
    color: $color-alert;
  }

  &__table {
    width: auto;
    margin-bottom: $spacing-l;
  }

  &__manual-searching-button {
    margin-left: -$spacing-m;
  }

  &__manual-selectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $spacing-l;
    margin-top: $spacing-m;
  }
  &__selector {
    width: 100%;
  }
}
