@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.RegisterUserPage {
  width: 400px;
  height: 100%;
  margin: $spacing-xxxl auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include small-devices {
    width: 90%;
  }

  &--alignTop {
    height: auto;
  }

  &__logo {
    width: 300px;
    margin-bottom: $spacing-xxxxl;

    @include small-devices {
      width: 4rem;
    }
  }
}
