@import 'styles/media-queries.scss';

.AddUserIntoAsset {
  &__input {
    width: 300px;

    @include small-devices {
      width: auto;
    }
  }
}
