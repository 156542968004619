@import 'styles/color.scss';
@import 'styles/global.scss';
@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';

.TechSupportPage {
  &__header {
    @extend %header;
  }

  &__wrapper {
    @extend %pageWrapper;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include large-devices {
      flex-direction: column;
    }
  }

  &__main {
    width: 65%;

    @include large-devices {
      width: 100%;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-description {
      margin: $spacing-xl 0 $spacing-xxxl 0;
    }
  }

  &__divider {
    height: auto !important;

    &--horizontal {
      @include large-devices {
        display: none;
      }
    }
  }
}
