@import 'styles/spacing.scss';

.ProjectConfigAreaForm {
  &__image-info {
    margin-right: $spacing-xs;
  }
  &__horizontalConfig-hidden {
    visibility: hidden;
  }
  &__horizontalConfig-item {
    margin: 0px 24px;
  }
  &__horizontalConfig-item-content {
    margin-bottom: 18px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 12px;
  }
  &__horizontalConfig-title {
    margin: $spacing-l 0px; 
  }
  &__horizontalConfig-item-input {
    margin-top: 16px !important;
  }
  &__delete-button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__add-button {
    display: flex;
    justify-content: center;
    margin-top: $spacing-xl;
  }
}