@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.RegisterUserConfirmation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__icon {
    font-size: 3rem;
    color: $color-success !important;
    margin: 0 auto $spacing-xs auto;

    @include small-devices {
      font-size: 2rem;
    }
  }

  &__submit {
    width: 100%;
    margin-top: $spacing-l;

    @include small-devices {
      margin-top: $spacing-xxxxxl;
    }
  }
}
