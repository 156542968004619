@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.CommonAreasAndBookingsListEmptyTemplate {
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;

    &-message {
      width: 366px;
      margin: 72px 0;
    }
  }
}
