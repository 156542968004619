@import 'styles/global.scss';
@import 'styles/media-queries.scss';

.TicketsPage {
  &__form {
    width: 80%;

    @include small-devices {
      width: 100%;
    }
  }
}
