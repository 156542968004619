@import 'styles/color.scss';
@import 'styles/spacing.scss';

.Modal__container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-black-50;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.Modal__content {
  width: 70%;
  height: 70%;
  display: flex;
  justify-content: center;
}

.Modal__body {
  width: 540px;
  height: 70px;
  padding: 0 20px;
  background-color: $color-background;
  border-top: 1px solid $color-background;
  border-bottom: 1px solid $color-background;
  border-radius: $spacing-s;
}
