@import 'styles/color.scss';
@import 'styles/spacing.scss';

.StatusListHistory {
  &__wrapper {
    margin: $spacing-xs;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-m;
  }

  &__loadMore {
    text-align: center;
    margin-top: $spacing-s;
    height: $spacing-xxl;
    line-height: $spacing-xxl;
  }
}
