@import 'styles/spacing.scss';

.CheckInListTemplate {
  &__wrapper {
    margin: $spacing-l;
    padding: $spacing-xl;
  }
}

.CheckInListTemplate__wrapper div.ant-table-body {
  max-height: 100% !important;
}
