@import 'styles/font.scss';
@import 'styles/global.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.UsersPage {
  &__header {
    @extend %header;
  }

  &__cell {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    @extend %pageWrapper;
  }

  &__custom-contextual-button {
    display: inline-block;
    width: 100%;
    padding: ($spacing-xxxs + $spacing-xxs) $spacing-s;
    cursor: pointer;
    border: none;
  }

  &__custom-contextual-button input[type='file'] {
    display: none;
  }

  &__custom-contextual-button:hover {
    background-color: $color-gray-extra-extra-light;
  }
}
