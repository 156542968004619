@import 'styles/color.scss';
@import 'styles/spacing.scss';

.TabSidebarMenuListItem {
  padding: $spacing-xs;
  color: $color-text;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $color-text-link;
  }

  &--active {
    background: $color-corporate-light;
    color: $color-text-link;
    border-radius: 8px;
    font-weight: bold;
  }
  &--disabled {
    color: $color-text-disabled;
    cursor: no-drop;
  }
}
