@import 'styles/spacing.scss';

.CommonAreasBookingsListLoadingTemplate {
  &__body {
    display: flex;
    align-items: flex-start;
    padding: $spacing-xl;
    flex-direction: column;
    gap: $spacing-m;
    width: 100%;

    &-skeleton-header {
      border-radius: 8px;
      overflow: hidden;
      height: 30px !important;
      width: 250px !important;
    }

    &-skeleton {
      border-radius: 8px;
      overflow: hidden;
      height: 30px !important;
      width: 100% !important;
    }
  }
}
