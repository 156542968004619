.CommonAreaContentVisorTemplate {
  &__container {
    padding: 22px;
    background-color: #fafafa;
    width: 100%;
  }
  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__switch {
    width: 10%;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
