@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.ProjectIntercomsErrorTemplate {
  &__container {
    width: 100%;
    background-color: $color-background;
    height: 62.5vh;
    border-radius: 8px;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__message-content {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-l;
  }

  &__message-title {
    font-size: $font-size-l;
    color: $color-text-title;
    font-weight: 500;
    margin: $spacing-xl 0 $spacing-m 0;
  }

  &__message-description {
    font-size: $font-size-base;
    color: $color-text-subtitle;
    line-height: 24px;
    font-weight: 400;
  }
}
