@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';

.TabVideos {
  &__grid {
    margin: $spacing-s;
  }

  &__card {
    width: 560px;

    @include medium-devices {
      width: 350px;
    }
  }
}
