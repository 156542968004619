@import 'styles/color.scss';

.Thumbnail {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid $color-gray-light;
    width: 160px;
    height: 100px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $color-text-disabled;
  }
}
