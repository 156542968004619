@import 'styles/color.scss';

.Icon {
  &__corporate {
    color: $color-corporate !important;
  }
  &__gray-extra-light {
    color: $color-gray-extra-light !important;
  }
  &__avatar {
    text-transform: uppercase;
    font-weight: bold;
    &--enabled {
      color: $color-text-white !important;
      background-color: $color-complementary !important;
    }
  }
}
