@import 'styles/spacing.scss';
@import 'styles/color.scss';

.SupportTicketsSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: $spacing-xl;
}
