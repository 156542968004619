@import 'styles/color.scss';
@import 'styles/spacing.scss';

.UploadButton {
  &__wrapper {
    width: 124px;
    height: 102px;
    border: 1px dashed $color-text-disabled;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text {
    text-align: center;
    margin-top: $spacing-xs;
    line-height: 1.25;
  }
}
