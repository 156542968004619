@import 'styles/spacing';

.CustomizationForm {
  &__logo-info {
    margin-right: $spacing-xs;
  }

  &__alert {
    margin-top: $spacing-xxxl;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: $spacing-xxxxl 0;
  }

  &__cancel-button {
    margin-right: $spacing-m;
  }
}
