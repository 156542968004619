// reference: https://ant.design/docs/spec/layout
$spacing-one-pixel: 1px;
$spacing-xxxs: 2px;
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 40px;
$spacing-xxxxl: 60px;
$spacing-xxxxxl: 80px;
