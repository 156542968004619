@import 'styles/spacing.scss';
@import 'styles/color.scss';

.CommonAreaUpdateTemplate {
  &__body {
    display: flex;
    padding: $spacing-xl;
    flex-direction: column;
    gap: $spacing-m;
    width: 100%;

    &-required {
      display: flex;
      justify-content: flex-end;
      font-weight: 500;
      color: $color-text-subtitle;
    }
  }
}
