@import 'styles/global.scss';
@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';

.AlfredDetailTemplate {
  &__header {
    @extend %header;
  }

  &__title {
    width: 200px !important;
  }

  &__tabs {
    @extend %pageWrapper;
  }
}
