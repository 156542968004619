@import 'styles/color.scss';
@import 'styles/font.scss';

.PercentStatus {
  text-align: center;

  &__text {
    font-size: $font-size-base;
  }

  &__value {
    cursor: pointer;
    font-size: $font-size-base;
    color: $color-gray;

    &--not-reported {
      font-size: $font-size-s;
    }
  }

  &__popover {
    font-size: $font-size-base;
    &-threshold {
      font-size: $font-size-s;
    }
  }

  &__threshold {
    font-size: $font-size-s;
    font-style: italic;
    color: $color-alert;
  }
}
