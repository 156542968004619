.CommonAreaTimeAvailabilitySelector {
  &__timeRange-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }

  &__timeRange {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &__timeRange-wrapper-delete-button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}
