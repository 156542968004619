@import 'styles/spacing.scss';
@import 'styles/color.scss';

.TagError {
  padding: $spacing-xxs $spacing-xs;
  background-color: $color-error-background;
  border-radius: 4px;
  font-weight: bold;
  color: $color-corporate-sunset-red;
}
