@import 'styles/spacing.scss';
@import 'styles/color.scss';

.CommonAreasAndBookingsMenuTemplate {
  &__body {
    margin: $spacing-l;
    display: flex;
    justify-content: space-between;
    gap: $spacing-l;
    min-height: calc(100vh - 176px);

    &-menu {
      width: 200px;
      margin-top: $spacing-xs;
      height: 100%;
    }

    &-info {
      display: flex;
      justify-content: center;
      width: calc(100% - 200px);
      border: 1px solid $color-black-10;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
