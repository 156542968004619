@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/media-queries.scss';

$height: 404px;

.OccupationLoading {
  &__container {
    width: 33.33%;
    background-color: $color-background;
    min-height: $height;
    border-radius: 8px;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    @include medium-devices {
      width: 100%;
    }
  }

  &__title {
    width: 117px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
  }

  &__title-skeleton {
    height: 50px !important;
  }

  &__content {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  &__content-skeleton {
    width: 800px !important;
    height: calc($height - 50px - ($spacing-m * 3)) !important;
  }
}
