@import 'styles/media-queries.scss';

.TabEvents {
  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;

    @include medium-devices {
      flex-direction: column;
    }
  }
}
