@import 'styles/spacing.scss';

.CommonAreaFormGrid {
  &__container {
    width: 100%;
    padding: 0 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }

  &__grid-item {
    font-weight: 700;

    &-title {
      font-weight: bold;
      padding: 10px;
    }

    &-text {
      padding: 10px;
    }
  }

  &__grid-item:last-child {
    grid-column: span 3;
  }

  &__userTitle {
    width: 40%;
  }

  &__userDescription {
    width: 70%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    &--update {
      justify-content: space-between;
    }
    &--create {
      justify-content: flex-end;
    }
  }

  &__label {
    font-weight: 700;
  }

  &__info-label {
    margin-right: $spacing-s;
  }

  &__image-wrapper {
    margin-top: -16px;
  }

  &__image-wrapper-info {
    margin-right: 12px;
  }

  &__close-button {
    margin-right: 8px !important;
  }
}
