.IntercomsTable {
  display: flex;
  width: 100%;

  &__button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
