@import 'styles/color.scss';
@import 'styles/font.scss';

.EventsAndNewsContentGrid {
  &__container {
    padding: 0 10px;
  }

  &__title {
    font-size: $font-size-l;
    font-weight: 700;
    color: $color-gray;
  }

  &__description {
    font-size: $font-size-base;
    font-weight: 400;
    color: $color-gray;
  }

  &__title, &__description {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__image {
    margin-top: 8px;
    margin-bottom: 8px;
    max-width: 100%;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 88px;
  }

  &__link-button {
    color: $color-corporate;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px $color-corporate solid;
  }

  &__icon {
    margin-left: 19px;
  }
}
