@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.EventItem {
  padding: $spacing-s;
  width: 70%;

  @include medium-devices {
    width: 100%;
  }
}
