@import 'styles/spacing.scss';
@import 'styles/color.scss';

.Columns {
  &__wrapper {
    padding-top: $spacing-s !important;
    padding-bottom: $spacing-s !important;
  }

  &__wrapper-button {
    padding: $spacing-xs $spacing-xl !important;
  }

  &__wrapper-delete {
    padding: $spacing-xs $spacing-xl !important;
    color: $color-corporate-sunset-red !important;
  }
}
