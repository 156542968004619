@import 'styles/spacing.scss';
@import 'styles/color.scss';

.TagDefault {
  padding: $spacing-xxs $spacing-xs;
  background-color: $color-gray-extra-extra-light;
  border-radius: 4px;
  font-weight: bold;
  color: $color-text;
}
