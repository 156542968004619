@import 'styles/global.scss';
@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.Dashboard {
  &__wrapper {
    @extend %pageWrapper;

    @include medium-devices {
      margin: 0 $spacing-m;
    }
  }

  &__charts {
    display: flex;
    flex-direction: row;
    gap: $spacing-m;
    overflow: hidden;

    @include medium-devices {
      flex-direction: column;
    }
  }
}
