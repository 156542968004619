@import 'styles/color.scss';
@import 'styles/spacing.scss';

.TabProducts {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__wrap {
    margin: $spacing-s;
  }

  &__card {
    width: 300px;

    &-image {
      border: 1px dashed $color-background;
    }
  }
}
