@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AccessPasswordDrawerInfoAlert {
  &__info {
    border-radius: 8px;
    border: 1px solid $color-gray-extra-light;
    padding: $spacing-m;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  &__info-icon {
    width: 24px;
  }
  &__info-title {
    font-size: $font-size-base;
    line-height: 24px;
    font-weight: 600;
    color: $color-text;
    margin: $spacing-xxxs 0 $spacing-xxs 0;
  }
  &__info-text {
    font-size: $font-size-base;
    line-height: 20px;
    font-weight: 400;
    color: $color-text;
    margin-bottom: $spacing-xxxs;
  }
}
