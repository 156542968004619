@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/spacing.scss';

.Block {
  &__table {
    margin-bottom: $spacing-xxl;
  }

  &__title {
    margin-bottom: $spacing-l;
    display: flex;
    align-items: baseline !important;
  }
}
