@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/media-queries.scss';

.DashboardActiveGatewaysErrorTemplate {
  &__wrapper {
    background-color: $color-background;
    border-radius: 8px;
    padding: $spacing-m;
    margin-top: $spacing-m;
    display: flex;
    flex-direction: row;
    gap: $spacing-m;
  }

  &__data {
    width: 33.33%;

    @include medium-devices {
      width: 100%;
    }
  }

  &__data-header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-size-l;
    font-weight: 600;
    color: $color-text-title;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: $font-size-base;
    font-weight: 400;
    color: $color-text-subtitle;
    margin-bottom: 0;
  }

  &__data-content {
    border: 1px solid $color-gray-extra-light;
    border-radius: 8px;
    height: 308px;
    margin-top: $spacing-m;
    padding: $spacing-m;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__data-content-info {
    display: block;
    font-size: $font-size-s;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    color: $color-text-title;
  }

  &__data-content-info-value {
    font-size: $font-size-xxl;
    font-weight: 500;
    text-align: center;
    color: $color-text-title;
  }

  &__chart {
    width: 66.66%;

    @include medium-devices {
      width: 100%;
    }
  }

  &__chart-content {
    border: 1px solid $color-gray-extra-light;
    border-radius: 8px;
    height: 308px;
    padding: $spacing-m;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__chart-header {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-m;
  }

  &__chart-content-info {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-l;
  }

  &__chart-content-info-title {
    font-size: $font-size-l;
    color: $color-text-title;
    font-weight: 500;
    margin: $spacing-xl 0 $spacing-m 0;
  }

  &__chart-content-info-description {
    font-size: $font-size-base;
    color: $color-text-subtitle;
    line-height: 24px;
    font-weight: 400;
  }
}
