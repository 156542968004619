@import 'styles/spacing.scss';

.LogListTemplate {
  &__wrapper {
    margin: $spacing-l;
    padding: $spacing-xl;

    div.ant-col {
      display: flex;
      justify-content: flex-end;
    }

    div.ant-table-body {
      max-height: 100% !important;
    }
  }
}