@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.CommonAreasV2Page {
  &__body {
    margin: $spacing-l;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 176px);

    &-container {
      width: 366px;
    }

    &-icon {
      margin-bottom: $spacing-l;
    }

    &-text {
      color: $color-gray-backgroung;
      font-weight: 400;
      font-size: $font-size-base;
      line-height: $spacing-xl;
    }

    &-link {
      display: flex;
      justify-content: flex-end;
      margin-top: $spacing-xxxl;
      font-weight: 500;
      font-size: $font-size-base;
      line-height: $spacing-xl;
      background-color: transparent;
    }
  }
}
