@mixin extra-large-devices {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin large-devices {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin medium-devices {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin small-devices {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin extra-small-devices {
  @media (max-width: 414px) {
    @content;
  }
}
