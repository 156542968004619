@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/font.scss';

.MaintenanceTemplate {
  &__container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  &__empty {
    margin: 72px 0;
  }
  &__container-info {
    max-width: 578px;
  }
  &__icon {
    margin: 0 0 $spacing-xl $spacing-xl;
  }
  &__title {
    font-weight: 500;
    font-size: $font-size-xl;
    margin: 0 $spacing-xl $spacing-m $spacing-xl;
  }
  &__text {
    color: $color-gray-backgroung;
    font-weight: 400;
    font-size: $font-size-l;
    line-height: $spacing-xl;
    margin: $spacing-xl;
  }
  &__wrapper-link {
    display: flex;
    justify-content: flex-end;
    margin: $spacing-xxxxl $spacing-xxxl 0 0;
  }
  &__link {
    font-weight: 500;
    font-size: $font-size-base;
    line-height: $spacing-xl;
    color: $color-text-link;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
}
