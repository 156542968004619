@import 'styles/global.scss';
@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';

.AssetPage {
  &__header {
    @extend %header;
  }

  &__title {
    width: 200px !important;
  }

  &__main {
    width: 100%;
    flex: 1 1 70%;

    @include large-devices {
      padding-right: none;
      flex: 1 1 100%;
      padding-bottom: $spacing-l;
    }
  }

  &__did {
    cursor: pointer;
  }

  &__tab {
    @extend %pageWrapper;
  }

  &__extraButtons,
  &__extraMenu {
    cursor: pointer;
  }
}
