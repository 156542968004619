@import 'styles/global.scss';
@import 'styles/spacing.scss';

.SuperSearchPage {
  &__divider {
    margin-top: 0 !important;
  }

  &__wrapper {
    @extend %pageWrapper;
  }
}
