@import 'styles/spacing.scss';
@import 'styles/color.scss';

.SuperSearch {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.6s;

  &:hover {
    background-color: $color-gray-extra-light;
  }

  &__modal {
    display: flex;
    align-items: center;
  }

  &__input {
    margin-left: 10px;
    border: 0px;
    width: 100%;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    display: flex;
    fill: $color-gray-light;

    &-close {
      cursor: pointer;
    }
  }
}
