.CommonAreaContentGrid {
  &__container {
    width: 90%;
    padding: 0 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }

  &__grid-item {
    padding: 20px;

    &-title {
      font-weight: bold;
      padding: 10px;
    }

    &-text {
      padding: 10px;
    }
  }

  &__grid-item:last-child {
    grid-column: span 3;
  }
}
