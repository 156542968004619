@import 'styles/spacing.scss';

.AccessRoomListTemplate {
  &__wrapper {
    margin: $spacing-l;
    padding: $spacing-xl;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $spacing-xl;
  }
}

.AccessRoomListTemplate__wrapper div.ant-table-body {
  max-height: 100% !important;
}
