@import 'styles/font.scss';

.UsersTabColumns {
  &__cell {
    display: flex;
    flex-direction: column;
  }

  &__date {
    font-size: $font-size-s;
  }
}
