@import 'styles/color.scss';

.ProjectAssetsColumns {
  &__linkButton {
    border: none;
    background-color: transparent;
    color: $color-text-link;
    cursor: pointer;

    :hover {
      color: $color-gray;
    }
  }
}
