@import 'styles/spacing.scss';

.AlfredTableTemplate {
  &__toolbar {
    > * {
      margin-bottom: $spacing-m;
    }
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $spacing-l 0 $spacing-l $spacing-l !important;
  }
}
