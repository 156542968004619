@import 'styles/global.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.UserAvatarIdentity {
  &__text {
    &-email {
      @include medium-devices {
        display: none;
      }
    }
  }
}
