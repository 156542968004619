@import 'styles/spacing';
@import 'styles/color';
@import 'styles/media-queries.scss';

.AdminTemplate {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
    padding-left: $spacing-s !important;
    padding-right: $spacing-xl !important;
    border-bottom: 1px solid $color-gray-extra-extra-light;
  }

  &__logo {
    margin: $spacing-m 0;
    height: 32px;
    display: flex;
  }

  &__topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__content {
    padding-bottom: $spacing-xxxl;

    @include large-devices {
      animation: closed 0.9s;

      @keyframes closed {
        0% {
          margin-left: 200px;
        }
        100% {
          margin-left: 0px;
        }
      }
      margin-left: 0px;
    }
  }

  &__sider {
    border-right: 1px solid $color-gray-extra-extra-light;
    height: 73px;
    background-color: $color-background;
    height: auto;
  }

  &__waterMark-wrapper {
    margin: $spacing-xxxl $spacing-xxl $spacing-xl $spacing-xxl;
  }

  &__drawer-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  &__drawer-wrapper-waterMark {
    width: auto;
    margin: 0 $spacing-xl;
  }
}
