@import 'styles/font.scss';
@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.GatewayInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include medium-devices {
    flex-direction: column;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    &-contextualMenu {
      width: $spacing-xl;
    }
  }

  &__image {
    min-width: 100px;
  }

  &__divider {
    height: auto !important;

    @include medium-devices {
      display: none;
    }
  }

  &__box {
    max-width: 300px;
    &-one {
      @include large-devices {
        margin-top: $spacing-s;
      }
    }

    &-three {
      margin-left: $spacing-xl;
      @include large-devices {
        margin-left: 0px;
      }
    }

    &-inline-info > div:first-of-type {
      display: inline-block;
      margin-left: $spacing-s;
    }
  }

  &__maxi-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include large-devices {
      flex-direction: column;
    }
  }

  &__text-updatable {
    margin-bottom: 0;
  }

  &__text-updatable-secondary {
    margin-top: $spacing-m;
    margin-bottom: $spacing-xs;
  }

  &__text-updatable-error {
    font-size: $font-size-s;
    color: $color-corporate-sunset-red;
    margin-bottom: 0;
  }

  &__text-popover {
    margin: $spacing-xs;
  }

  &__button {
    margin-left: -$spacing-m;
  }

  &__popover {
    cursor: pointer;
  }

  &__qid {
    cursor: pointer;
  }
}
