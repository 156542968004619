@import '~antd/dist/antd.less';
@import 'styles/color.less';

// VARIABLES
// Default less: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// Text general color
@text-color: @color-text;
// @text-color-secondary: @color-text-subtitle;
@heading-color: @color-text-title;

// General fonts
@font-family: 'Inter' !important;
@btn-font-weight: 600 !important;

// General
@primary-color: @color-corporate; // primary color for all components

@link-color: @color-corporate; // link color
// @success-color: @color-success; // success state color
// @error-color: @color-alert; // error state color
@normal-color: @color-complementary;

// Sidebar
@layout-sider-background: @color-white;
@menu-inline-submenu-bg: @color-corporate-light;

// Header
@layout-header-background: @color-background; // background color top bar

// Sidebar Menu
@menu-bg: @color-background;
@menu-item-color: @color-text;
@menu-item-active-bg: @color-corporate-light;
@menu-item-active-border-width: 0px;
@menu-highlight-color: @color-corporate;
@menu-item-active-bg: @color-corporate-light;
@menu-horizontal-line-height: 0px;

// FONT-FACE
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
