@import 'styles/spacing.scss';

.WaterMark {
  &__wrapper {
    padding: 0 $spacing-m;
    display: flex;
    flex-direction: column;
  }

  &_text {
    color: #bbbbbb;
    font-size: 12px;
    margin-bottom: 0;
  }
}
