.EventsAndNewsLoadingTemplate {
  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__menu {
    width: 20%;
    padding: 20px;
  }
  &__content {
    width: 80%;
    padding: 20px;
  }
}
