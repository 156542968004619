@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/font.scss';

.InvitationItem {
  &__icon {
    background-color: $color-success !important;

    &--no-active {
      background-color: $color-alert !important;
    }
  }

  &__list-access {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
