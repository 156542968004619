@import 'styles/font.scss';

.TechSupportsColumns {
  &__icon {
    font-size: $font-size-l !important;
  }
  &__cell {
    display: flex;
    flex-direction: column;
  }
  &__creation-date {
    font-size: $font-size-s;
  }
  &__disabled {
    cursor: not-allowed !important;
    text-decoration: none !important;
  }
}
