@import 'styles/spacing.scss';

.AccessDevicesListTemplate {
  &__wrapper {
    margin: $spacing-l;
    padding: $spacing-xl;
  }

  &__extra-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacing-m;
  }

  &__extra-button .ant-input-search {
    width: 300px;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: $spacing-l 0 $spacing-l $spacing-l !important;
  }
}

.AccessRoomListTemplate__wrapper div.ant-table-body {
  max-height: 100% !important;
}
