@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AccessHotelKeyReaderDrawer {
  &__wrapper .ant-drawer-header {
    padding: $spacing-xl $spacing-xxxl;
  }

  &__wrapper .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 600;
  }

  &__wrapper .ant-drawer-header-title button {
    margin-right: 0;
  }

  &__wrapper .ant-drawer-body {
    padding: $spacing-xl $spacing-xxxl $spacing-xxxxxl $spacing-xxxl;
  }

  &__wrapper footer {
    position: absolute;
    height: 70px;
    width: 100%;
    background-color: $color-text-white;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $spacing-l;
    gap: $spacing-m;
    border-top: 1px solid $color-gray-extra-extra-light;
  }

  &__main-wrapper {
    padding-bottom: $spacing-xl;
  }

  &__main-wrapper-input {
    display: flex;
    flex-direction: row;
  }

  &__main-label {
    margin-bottom: $spacing-xs;
  }

  &__info {
    margin-bottom: $spacing-xl;
  }

  &__content-wrapper {
    padding: $spacing-xl 0;
  }

  &__content-title {
    font-size: $font-size-l;
    font-weight: 600;
    margin-bottom: 0;
  }

  &__content-wrapper-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__content-wrapper-header-button span {
    color: $color-corporate;
    font-size: $font-size-base;
    font-weight: bold;
  }

  &__info-room-label {
    font-weight: bold;
    padding-bottom: $spacing-xxs;
  }

  &__body {
    &-empty-container {
      width: 366px;
    }

    &-empty-icon {
      margin-bottom: $spacing-l;
    }

    &-empty-text {
      color: $color-gray-backgroung;
      font-weight: 400;
      font-size: $font-size-base;
      line-height: $spacing-xl;
    }
  }

  &__item {
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
  }

  &__item_wrapper {
    border: $spacing-one-pixel solid $color-text-disabled;
    border-radius: $spacing-xs;
    padding: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}
