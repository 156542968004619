@import 'styles/font.scss';
@import 'styles/color.scss';

.TitleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__title {
    display: flex;
    align-items: baseline !important;
  }

  &__query {
    font-size: $font-size-xl;
  }

  &__icon {
    cursor: pointer;
    fill: $color-gray-light;
  }
}
