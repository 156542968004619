@import 'styles/color.scss';
@import 'styles/spacing.scss';

.DeleteGatewayForm {
  &__error {
    position: absolute;
    color: $color-alert-ant;
    margin-top: -$spacing-xl;
  }
}
