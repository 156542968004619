@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

$height: 404px;

.UsersErrorTemplate {
  &__container {
    width: 66.66%;
    background-color: $color-background;
    height: $height;
    border-radius: 8px;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    @include medium-devices {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-size-l;
    font-weight: 600;
    color: $color-text-title;
    margin-bottom: 0;
  }

  &__subtitle {
    font-size: $font-size-base;
    font-weight: 400;
    color: $color-text-subtitle;
    margin-bottom: 0;
  }

  &__message-container {
    border: 1px solid $color-gray-extra-light;
    border-radius: 8px;
    height: 308px;
    padding: $spacing-m;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__message-content {
    display: flex;
    flex-direction: column;
    margin: 0 $spacing-l;
  }

  &__message-title {
    font-size: $font-size-l;
    color: $color-text-title;
    font-weight: 500;
    margin: $spacing-xl 0 $spacing-m 0;
  }

  &__message-description {
    font-size: $font-size-base;
    color: $color-text-subtitle;
    line-height: 24px;
    font-weight: 400;
  }
}
