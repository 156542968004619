@import 'styles/color.scss';
@import 'styles/font.scss';

.Text {
  color: $color-text !important;

  &__secondary {
    color: $color-text-subtitle !important;
  }

  &__success {
    color: $color-text-success !important;
  }

  &__warning {
    color: $color-text-warning !important;
  }

  &__danger {
    color: $color-text-alert !important;
  }

  &__gray {
    color: $color-text-disabled !important;
  }

  &__disabled {
    @extend .Text__gray;
    cursor: not-allowed;
    text-decoration: none;
  }
  &__date {
    @extend .Text__secondary;
    font-size: $font-size-s !important;
  }
  &__pointer {
    cursor: pointer;
  }

  &__link {
    cursor: pointer;
    color: $color-text-link !important;
    &--accept {
      @extend .Text__link;
      color: $color-text-success !important;
    }
    &--cancel {
      @extend .Text__link;
      color: $color-text-alert !important;
    }
  }
}
