@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.AccessPasswordDrawerForm {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__input-wrapper {
    margin-top: $spacing-m;
    width: 100%;
  }

  &__input-label {
    margin-bottom: $spacing-xs;
  }

  &__input-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right-width: 0 !important;
  }

  &__show-button {
    width: 100px;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  &__contextual-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__contextual-menu .ContextualMenu__icon {
    margin: ($spacing-xxxl + $spacing-xs) 0 0 $spacing-xs;
  }

  &__error {
    color: $color-alert;
    font-size: $font-size-base;
  }
}
