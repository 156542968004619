@import 'styles/color.scss';
@import 'styles/spacing.scss';

.GatewaysListColumns {
  &__alias {
    position: absolute;
    margin-bottom: 0;
    margin-left: -$spacing-m;
    margin-top: -$spacing-xxs;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__notReported {
    color: $color-text-disabled;
  }
}
