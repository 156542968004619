@import 'styles/spacing.scss';

.TableHeading {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: $spacing-l;
  }

  &__info-area {
    display: flex;
    align-items: center;
    gap: $spacing-m;
  }

  &__actions-area {
    display: flex;
    gap: $spacing-m;
  }
}