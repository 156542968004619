@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.CreateAssetDrawer {
  &__wrapper .ant-drawer-header {
    padding: $spacing-xl $spacing-xxxl;
  }

  &__wrapper .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: 600;
  }

  &__wrapper .ant-drawer-header-title button {
    margin-right: 0;
  }

  &__wrapper .ant-drawer-body {
    padding: $spacing-xl $spacing-xxxl $spacing-xxxxxl $spacing-xxxl;
  }

  &__wrapper footer {
    position: absolute;
    height: 70px;
    width: 100%;
    background-color: $color-text-white;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: $spacing-l;
    gap: $spacing-m;
    border-top: 1px solid $color-gray-extra-extra-light;
  }

  &__main-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: $spacing-m;
  }

  &__main-wrapper-with-border {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-l;
  }

  &__description {
    margin-bottom: $spacing-xs;
  }

  &__extra-button {
    margin-top: $spacing-l + $spacing-xs + $spacing-xxxs;
  }

  &__divider {
    margin: $spacing-xxs 0;
  }

  &__add {
    &-container {
      display: flex;
      flex-wrap: nowrap;
      padding: $spacing-xs;
    }

    &-input {
      flex: auto;
    }

    &-anchor {
      flex: none;
      padding: $spacing-xs;
      display: block;
      cursor: pointer;
      color: $color-text !important;
    }
  }
}

.ant-form-inline .ant-form-item {
  margin-right: 0 !important;
  flex: 1 !important;
}

.ant-form-item {
  margin-right: 0 !important;
  flex: 1 !important;
}
