@import 'styles/spacing.scss';
@import 'styles/color.scss'; 

.ProjectConfigAreaDrawer {
  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer {
    justify-content: flex-end;
    padding: $spacing-l $spacing-xs;
  }
}