$font-size-s: 12px;
$font-size-base: 14px;
$font-size-l: 16px;
$font-size-xl: 20px;
$font-size-xxl: 24px;
$font-size-h1: 38px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 16px;
