@import 'styles/spacing';
@import 'styles/color';
@import 'styles/media-queries.scss';

.LegalDocumentationPage {
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-left: 0 !important;
    padding-left: $spacing-s !important;
    padding-right: $spacing-xl !important;
    border-bottom: 1px solid $color-gray-extra-extra-light;

    @include small-devices {
      padding-right: $spacing-s !important;
    }
  }

  &__logo {
    margin: $spacing-xs 0;
    height: 48px;
    width: 250px !important;

    @include small-devices {
      width: 160px !important;
    }
  }

  &__topBarRight {
    margin: $spacing-xs 0;
    height: 48px;
    width: 76px !important;

    @include small-devices {
      width: 50px !important;
      min-width: 50px !important;
    }
  }

  &__menuItem {
    margin: $spacing-xxs $spacing-xs;
  }

  &__sider {
    border-right: 1px solid $color-gray-extra-extra-light;
    height: 73px;
    background-color: $color-background;
    height: auto;
  }

  &__sider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }
}
