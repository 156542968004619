.DeviceHelpLoadingTemplate {
  &__active-toggle {
    display: flex;
    justify-content: space-between;
  }

  &__skeleton-input {
    width: 100% !important;
  }
  &__skeleton-text-area {
    width: 100% !important;
    height: 98px !important;
  }
}