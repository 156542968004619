.QRToolPage {
  &__container {
    max-width: 700px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__info {
    font-style: italic;
  }
}