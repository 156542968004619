@import 'styles/spacing.scss';

.IntercomEditForm {
  &__content {
    width: 100%;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__right-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: $spacing-xs;
  }
}
