.EventsAndNewsContentTemplate {
  &__container {
    padding: 40px;
    background-color: #fafafa;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
