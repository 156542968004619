@import 'styles/spacing.scss';

.CardQrTool {
  &__container {
    margin: $spacing-m;
  }

  &__card {
    width: 45%;
    min-width: 300px;
  }

  &__buttons {
    margin-top: $spacing-s;
  }
}