@import 'styles/color.scss';
@import 'styles/font.scss';
@import 'styles/spacing.scss';

.CreateAssetConfirmationModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: $spacing-l;

  &__icon {
    font-size: $font-size-xxl;
    color: $color-success !important;
    margin-right: $spacing-xs;
  }
}
