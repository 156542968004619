@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.GatewayStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include medium-devices {
    flex-direction: column;
    height: auto;
  }

  &__container {
    width: 100%;
    height: 100%;
    padding: $spacing-xs;
  }

  &__check {
    display: grid;
    align-items: center;
    grid-template-columns: 45% 45%;
    grid-column-gap: $spacing-xxs;
    grid-row-gap: $spacing-s;

    @include medium-devices {
      grid-template-columns: 100%;
      grid-column-gap: 0%;
      height: 250px;
    }
  }

  &__percent {
    display: grid;
    justify-items: center;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: $spacing-xxs;
    grid-row-gap: $spacing-l;

    @include medium-devices {
      justify-items: flex-start;
      margin-top: $spacing-s;
      grid-template-columns: auto auto;
      height: 400px;
    }
  }

  &__divider {
    height: auto !important;

    @include medium-devices {
      display: none;
    }
  }
}
