@import 'styles/media-queries.scss';
@import 'styles/spacing.scss';

.ListInvitationsModal {
  max-height: 600px;
  overflow: scroll;
  @include small-devices {
    max-height: 350px;
  }

  &__loadMore {
    text-align: center;
    margin-top: $spacing-s;
    height: $spacing-xxl;
    line-height: $spacing-xxl;
  }
}
