@import 'styles/spacing.scss';
@import 'styles/font.scss';

.ClientCustomizationTemplate {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $spacing-l;
  }

  &__form {
    width: 47.5%;
  }

  &__title {
    display: block;
    font-size: $font-size-xl;
    margin-bottom: $spacing-s;
  }

  &__preview {
    width: 47.5%;
    height: 300px;
  }
}
