@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.CheckInQRModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__loading {
    width: 448px;
    height: 252px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__icon {
    font-size: 40px;
    color: $color-text;
  }

  &__scan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 448px;
    height: 252px;
  }
}

.AssignRoomModal .ant-modal-content {
  width: 100%;
}

.AssignRoomModal .ant-form-item-explain-error {
  font-size: $font-size-s;
}
