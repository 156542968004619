@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.TenantsPreviewModal {
  &__csv-title {
    font-size: $font-size-xl;
    margin-bottom: -$spacing-xxxs;
  }

  &__csv-table-title {
    font-size: $font-size-l;
    margin-top: $spacing-l;
    margin-bottom: $spacing-s;
  }

  &__table-error {
    padding-bottom: $spacing-xxxxl * 2;
  }

  &__error-wrapper {
    position: absolute;
    bottom: $spacing-xxxxl + $spacing-m;
    background-color: $color-warning-background;
    width: calc(100% - ($spacing-xl * 2));
    border-radius: 8px;
    border: 1px solid $color-warning;
    display: flex;
    flex-direction: row;
    padding: $spacing-s $spacing-l;
    gap: $spacing-m;
  }

  &__error-icon svg {
    color: $color-orange;
  }

  &__error-title {
    font-weight: bold;
    margin-top: $spacing-xxxs;
    margin-bottom: $spacing-xs;
  }

  &__error-description {
    margin-bottom: $spacing-xs;
  }

  &__error-sending-wrapper {
    position: absolute;
    bottom: $spacing-xxxxl + $spacing-m;
    background-color: $color-error-background;
    width: calc(100% - ($spacing-xl * 2));
    border-radius: 8px;
    border: 1px solid $color-alert;
    display: flex;
    flex-direction: row;
    padding: $spacing-s $spacing-l;
    gap: $spacing-m;
  }

  &__error-sending-icon svg {
    color: $color-alert;
  }

  &__error-sending-title {
    font-weight: bold;
    margin-top: $spacing-xxxs;
    margin-bottom: $spacing-xs;
  }

  &__error-sending-description {
    margin-bottom: $spacing-xs;
  }
}
