@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.CommonAreasClientAndProjectSelectorTemplate {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: $spacing-xs;

    &--selector {
      width: 250px;
    }
  }

  &__body {
    margin: $spacing-l;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 176px);

    &-empty-container {
      width: 366px;
    }

    &-empty-icon {
      margin-bottom: $spacing-l;
    }

    &-empty-text {
      color: $color-gray-backgroung;
      font-weight: 400;
      font-size: $font-size-base;
      line-height: $spacing-xl;
    }
  }
}
