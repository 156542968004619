@import 'styles/spacing.scss';

.CommonAreasAndBookingsMenuLoadingTemplate {
  &__body {
    margin: $spacing-l;
    display: flex;
    gap: $spacing-l;
    min-height: calc(100vh - 176px);

    &-menu {
      width: 200px;
      height: 100%;

      &-skeleton {
        border-radius: 8px;
        overflow: hidden;
        height: 24px !important;
      }
    }
  }
}
