.CommonAreaBookingsEmptyTemplate {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__empty {
    margin: 72px 0;
  }
}
