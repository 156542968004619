@import 'styles/color.scss';

.Menu {
  display: flex;
  list-style-type: none;
  padding: 0px;
  margin: 10px;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &__Item {
    cursor: pointer;
    margin: 4px 0;
    padding: 10px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    fill: $color-text;
    height: 40px;

    &:hover {
      background-color: #F5F5F5;
      transition: 0.6s;
    }

    &--selected {
      background-color: $color-corporate-light !important;
      color: $color-corporate;
      
      svg {
        fill: $color-corporate;
      }
    }
  }

  &__Icon {
    margin-right: 12px;
    fill: $color-text;
  }
}