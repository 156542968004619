.EventsAndNewsFormCreateTemplate {
  &__container {
    padding: 22px;
    background-color: #fafafa;
    width: 100%;
  }
  &__required-info {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    color: #888888;
  }
}
