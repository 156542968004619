@import 'styles/color.scss';
@import 'styles/spacing.scss';

.UploadActionsVisor {
  &__container {
    height: 102px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-s;
    background-color: $color-background;
    border: 1px solid $color-text-disabled;

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  &__icons {
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-s;
  }
  &__icon {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &--hide {
      display: none;
    }

    &--disabled {
      cursor: not-allowed !important;
    }
  }
}
