@import 'styles/spacing.scss';

.FileUploader {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xs;
  }
}
