@import 'styles/color.scss';

.ImageActionsMolecule {
  &__container {
    height: 102px;
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: $color-background;
    border: 1px solid $color-text-disabled;
    &--disabled {
      opacity: 0.6;
    }
  }
  &__image {
    max-width: 90%;
    max-height: 100%;
    object-fit: cover;
  }
  &__icon {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
