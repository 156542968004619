@import 'styles/color.scss';
@import 'styles/media-queries.scss';
@import 'styles/spacing';

%header {
  background-color: $color-background;
}

%pageWrapper {
  padding: $spacing-l;
  @include medium-devices {
    padding: $spacing-l 0;
  }
}
