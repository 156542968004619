@import 'styles/spacing.scss';
@import 'styles/color.scss';

.TabCommonAreasAndBookingsMenu {
  &__title {
    padding: 0 $spacing-xs;
    color: $color-text;
    font-weight: bold;
  }

  &__link {
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: $color-text-link;
    }

    &--active {
      color: $color-text-link;
      cursor: pointer;
    }

    &--disabled {
      color: $color-text-disabled;
      cursor: no-drop;
    }
  }
}
