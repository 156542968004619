@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/media-queries.scss';

.TabConsumption {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include large-devices {
      flex-direction: column;
    }
  }

  &__chart {
    margin-top: $spacing_xxxl;
    margin-bottom: $spacing_xl;
  }

  &__slider {
    width: 100%;
  }

  &__select-sensor {
    width: 400px !important;
  }

  &__calc {
    &-error {
      color: $color-alert;
      font-style: italic;
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
  }
}
