@import 'styles/spacing.scss';

.LegalDocumentationEmptyTemplate {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__empty {
    margin: ($spacing-xxxxl + $spacing-s) 0;
  }

  &__switch {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__switch h2 {
    margin-bottom: 0;
  }
}
