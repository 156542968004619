@import 'styles/spacing.scss';

.LegalDocumentationContentTemplate {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-xxxl;
  }

  &__header h2 {
    margin-bottom: 0;
  }

  &__form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
