@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/media-queries.scss';

.PermissionsAccess {
  padding: 0 $spacing-xxxxl;

  @include small-devices {
    padding: 0;
  }

  &__item {
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: $spacing-s !important;
    border-bottom: 1px solid $color-gray-extra-extra-light !important;
  }
}
