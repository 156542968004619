@import 'styles/color.scss';

.TenantsPreviewColumns {
  &__text {
    margin-bottom: 0;
  }

  &__error {
    color: $color-alert !important;
  }
}

.ant-table-tbody > tr > td:has(.TenantsPreviewColumns__error) {
  background-color: $color-error-background;
}
