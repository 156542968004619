@import 'styles/font.scss';
@import 'styles/color.scss';
@import 'styles/spacing.scss';

.icon {
  &__wrapper {
    color: $color-gray-light;
    position: absolute;
    right: 0;
    top: 0;
    padding: $spacing-l $spacing-xl;
    cursor: pointer;
    transition: color ease-in-out 150ms;

    &:hover {
      color: $color-text;
    }
  }
  &__close {
    font-size: $font-size-l;
  }
}
