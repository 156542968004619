
@import 'styles/color.scss';
@import 'styles/spacing.scss';
@import 'styles/font.scss';

.ProjectLayoutConfigEmptyTemplate {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 62.5vh;
  }
  &__empty {
    margin: 72px 0;
  }
  &__container-info {
    width: 366px;
  }
  &__icon {
    margin-bottom: $spacing-l;
  }
  &__title {
    font-weight: 500;
    font-size: $font-size-l;
    line-height: $spacing-xl;
  }
  &__text {
    color: $color-gray-backgroung;
    font-weight: 400;
    font-size: $font-size-base;
    line-height: $spacing-xl;
  }
  &__wrapper-link {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-xxxl;
  }
  &__link {
    font-weight: 500;
    font-size: $font-size-base;
    line-height: $spacing-xl;
    color: $color-text-link;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
}
