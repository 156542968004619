@import 'styles/color.scss';
@import 'styles/spacing.scss';

.DaySelector {
  &__item {
    border: 1px solid #e1e1e1;
    background-color: #fafafa;
    padding: $spacing-xxs ($spacing-xs + $spacing-one-pixel);
    margin-right: $spacing-xs;
    margin-bottom: $spacing-xs;
    cursor: pointer;

    &--actived {
      border-color: $color-corporate;
    }

    &--disabled {
      color: $color-text-disabled;
      border: 1px solid $color-text-disabled;
      cursor: not-allowed;
    }
  }
}
