@import 'styles/color.scss';
@import 'styles/spacing.scss';

.PreView {
  &__container {
    width: 100%;
    height: 500px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $color-gray-extra-extra-light;
    overflow: hidden;

    -webkit-box-shadow: 0px 8px 28px -6px $color-black-25;
    -moz-box-shadow: 0px 8px 28px -6px $color-black-25;
    box-shadow: 0px 8px 28px -6px $color-black-25;
  }

  &__header {
    width: 100%;
    height: 64px;
    border-bottom: 1px solid $color-text-disabled;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-m;
  }

  &__logo {
    display: flex;
    height: 24px;
  }
}
