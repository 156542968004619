@import 'styles/spacing.scss';
@import 'styles/color.scss';

.Paper {
  padding: $spacing-l;
  border: $spacing-xxxs solid $color-background;
  border-radius: $spacing-xxs;
  background-color: $color-background;
  margin-bottom: $spacing-l;
  animation: show 1s !important;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
