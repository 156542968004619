@import 'styles/font.scss';
@import 'styles/spacing.scss';
@import 'styles/color.scss';

.WebAppPreview {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  
  &__container {
    position: relative;
    width: 414px;
    height: 566px;
    background-color: $color-gray-extra-extra-light;
    overflow: hidden;

    -webkit-box-shadow: 0px 8px 28px -6px $color-black-25;
    -moz-box-shadow: 0px 8px 28px -6px $color-black-25;
    box-shadow: 0px 8px 28px -6px $color-black-25;
  }

  &__container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    border-radius: 16px 16px 0 0;
  }

  &__header {
    &-browser {
      & img {
        border-radius: 16px 16px 0 0;
      }
    }

    &-image {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
  
      & img {
        min-width: 100%;
        min-height: 100%;
        background-color: $color-gray-extra-light;
      }
    }
  }

  &__widget {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 60%;
    width: 100%;
    background-color: $color-white;
    border-radius: 12px 12px 0 0;
    padding: 20px;
    z-index: 3;

    &-header {
      display: flex;
      justify-content: space-between;

      &-info {
        width: 180px;
        height: 32px;
        border-radius: 70px;
        background-color: $color-gray-extra-extra-light;
      }
    }

    &-custom-info {
      display: flex;
      padding: 16px;
      gap: 12px;
      border: 1px solid $color-gray-extra-light;
      border-radius: 8px;
      margin-top: $spacing-xxxl;

      &-body {
        display: flex;
        flex-direction: column;
      }

      &-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }
      
      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $color-text-title;
      }
    }

    &-body {
      margin-top: $spacing-xxl;
      margin-bottom: $spacing-xxl;
      display: flex;
      flex-direction: column;
    }
    
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }

    &-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: $color-text-grey;
    }

    &-slider{
      display: flex;
      justify-content: center;
    }
  }
}