.EventsAndNewsFormGrid {
  &__container {
    width: 100%;
    padding: 0 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    width: 50%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    &--update {
      justify-content: space-between;
    }
    &--create {
      justify-content: flex-end;
    }
  }

  &__links {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 16px;
  }

  &__link {
    width: 100%;
  }

  &__close-button {
    margin-right: 8px;
  }

  &__check {
    display: flex;
    flex-direction: row;
  }

  &__checkLabel {
    margin-left: 14px;
  }

  &__protocol {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
