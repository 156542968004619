@import 'styles/global.scss';

.AlfredMainTemplate {
  &__header {
    @extend %header;
  }

  &__wrapper {
    @extend %pageWrapper;
  }

  &__extra {
    padding: $spacing-l $spacing-l 0 $spacing-l;
  }
}
