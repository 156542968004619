@import 'styles/color.scss';
@import 'styles/text.scss';
@import 'styles/icon.scss';

body {
  margin: 0;
  padding: 0;
}

th {
  font-family: 'Inter' !important;
  font-weight: bold !important;
}

body,
html,
#root {
  height: 100%;
}

// FONT-FACE
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
