@import 'styles/global.scss';

.ProvisionedPage {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__form {
    width: 40%;
    min-width: 300px;
  }

  &__wrapper {
    @extend %pageWrapper;
  }
}
