@import 'styles/spacing.scss';
@import 'styles/color.scss';
@import 'styles/font.scss';

.KpisTemplate .site-collapse-custom-collapse {
  background-color: $color-background;
  border-radius: 8px;
  border-width: 0;
  margin: $spacing-l $spacing-l 0 $spacing-l;
  display: flex;
  flex-direction: column;
  gap: $spacing-m;
}

.KpisTemplate .site-collapse-custom-collapse .site-collapse-custom-panel {
  border-bottom: 0;
  padding-top: $spacing-xxs;
}

.KpisTemplate .site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header {
  display: block;
  font-size: $font-size-l;
  font-weight: 600;
  color: $color-text-title;
  margin-bottom: 0;
}

.KpisTemplate .site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-subtitle {
  font-size: $font-size-base;
  font-weight: 400;
  color: $color-text-subtitle;
  margin-bottom: 0;
}

.KpisTemplate {
  &__chart-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $spacing-m;
    overflow: hidden;
  }

  &__chart-element {
    flex: 1;
    min-width: 165px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $color-gray-extra-light;
    border-radius: 8px;
    padding: $spacing-xxxl $spacing-xxl;
  }

  &__chart-element-title {
    font-size: $font-size-s;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    color: $color-text-title;
  }

  &__chart-element-data {
    font-size: $font-size-xxl;
    font-weight: 500;
    text-align: center;
    color: $color-text-title;
    display: inline;
  }
}
