@import 'styles/spacing.scss';

.ProjectIntercomCreateTemplate {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 30%;
    margin: 0 auto;
    min-width: 400px;
    min-height: 62.5vh;
    padding-top: $spacing-xxxxl;
  }
}
