.ColorPicker {
  &__container {
    width: 210px;
    display: flex;
    justify-content: space-between;
  }

  &__input {
    width: 160px;
  }

  &__input-button {
    background-color: transparent;
    padding: 0 8px;
    border: none;
  }

  &__popover {
    position: absolute;
    z-index: 10;
    top: -108px;
    left: 230px;
  }
}
