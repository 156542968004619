@import 'styles/color.scss';
@import 'styles/global.scss';
@import 'styles/spacing.scss';

.ClientsPage {
  &__header {
    @extend %header;
  }

  &__button-create {
    margin-bottom: $spacing-m;
  }

  &__divider {
    margin: 0;
    border-color: $color-gray;
  }

  &__wrapper {
    @extend %pageWrapper;
  }
}
