@import 'styles/spacing.scss';
@import 'styles/color.scss';

.ColumnSearch {
  &__dropdown {
    padding: $spacing-s;
    width: 330px !important;
  }

  &__dropdown-date-wrapper {
    margin-bottom: $spacing-m !important;
  }

  &__dropdown-date-input {
    width: 100% !important;
  }

  &__dropdown-input {
    margin-bottom: $spacing-l !important;
    display: block !important;
  }

  &__dropdown-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__dropdown-button {
    width: auto !important;
    margin-left: $spacing-xs;
  }

  &__notReportedText {
    color: $color-text-disabled;
  }
}
