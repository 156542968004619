@import 'styles/font.scss';
@import 'styles/spacing.scss';

.AccessPasswordDrawerEditableForm {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &__input-wrapper {
    margin-top: $spacing-m;
    // margin-right: $spacing-m;
    width: 100%;
  }

  &__input-label {
    margin-bottom: $spacing-xs;
  }

  &__input-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right-width: 1px !important;
  }

  &__show-button {
    margin-top: $spacing-xxxl + $spacing-xxs + $spacing-xxxs;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    width: 170px;
  }
}
